import Image from 'next/legacy/image';
import styled from 'styled-components';
import { CSSProperties } from 'react';

export const AutoNextImage = styled.img`
  width: 100% !important;
  position: relative !important;
  height: auto !important;
  object-fit: contain;
  object-position: center;
`;

export const AutoNextImageContainer = styled.div<{objectFit?: CSSProperties['objectFit']}>`
  position: relative;
  & > img {
    object-position: center;
    object-fit: ${props => props.objectFit || 'cover'};
  }
`;
